import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';

import {
  getCarrierPromptsService,
  getLimitsService,
} from '@/fleet/services/carriers';
import { Mixpanel } from '@/fleet/config/mixpanel';
import {
  PRODUCT_VALUES,
  TODAY,
  limitsInitialValues,
  promptInitialValues,
} from '@/fleet/components/views/settings/constants';
import LimitSettings from '@/fleet/components/views/settings/Limits';
import PromptSettings from '@/fleet/components/views/settings/Prompts';
import useCarrier from '../../hooks/useCarrier';
import TankLevelAuthorization from '../../components/views/settings/TankLevelAuthorization';
import { TransactionSettings } from '../../components/views/settings/TransactionSettings';

export function Settings() {
  const [editingLimits, setEditingLimits] = useState(false);
  const [editingPrompts, setEditingPrompts] = useState(false);
  const [editingTransactionSettings, setEditingTransactionSettings] =
    useState(false);
  const [limitFormValues, setLimitFormValues] = useState(limitsInitialValues);
  const [promptFormValues, setPromptFormValues] = useState(promptInitialValues);
  const [containerFlexDirection, setContainerFlexDirection] = useState({
    flexDirection: 'row',
  });
  const { carrierUuid } = useParams();

  const carrier = useCarrier();

  const isDtnaCarrier = useMemo(() => {
    return carrier.isDtnaCarrier;
  }, [carrier]);

  useEffect(() => {
    Mixpanel.track('view_settings');
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1300) {
        setContainerFlexDirection({ flexDirection: 'column' });
      } else {
        setContainerFlexDirection({ flexDirection: 'row' });
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window]);

  useEffect(() => {
    if (!carrierUuid) {
      return;
    }
    getLimitsService(carrierUuid, TODAY).then(async (response) => {
      let newProducts = {};
      const data = response.data.limits;
      const hasAmountValue = !!Number(data[0].amount);

      if (hasAmountValue) {
        newProducts = { ...newProducts, limitType: 'amount' };
      } else {
        newProducts = { ...newProducts, limitType: 'gallons' };
      }

      await data.map((limit: any) => {
        Object.keys(PRODUCT_VALUES).map((product) => {
          if (limit.productCategory === product) {
            const keyValue =
              PRODUCT_VALUES[product as keyof typeof PRODUCT_VALUES];
            const amountValue =
              Number(limit.amount) === 0 ? limit.quantity : limit.amount;
            newProducts = { ...newProducts, [keyValue]: amountValue };
          }
        });
      });

      setLimitFormValues(
        newProducts as {
          limitType: string;
          tractorDiesel: string;
          reeferDiesel: string;
          def: string;
          oil: string;
          additives: string;
          merchandise: string;
          cashAdvance: string;
          services: string;
          maintenance: string;
          nonDieselFuels: string;
        },
      );
    });

    getCarrierPromptsService(carrierUuid).then(async (response) => {
      const data = response.data.prompt;
      const { hasOdometer, hasTrailerNumber, hasTripNumber, hasTruckNumber } =
        data;

      await setPromptFormValues({
        hasOdometer,
        hasTrailerNumber,
        hasTripNumber,
        hasTruckNumber,
      });
    });
  }, [carrierUuid]);

  return (
    <>
      <Box fontSize={20} fontWeight={600} mb={2}>
        Settings
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={containerFlexDirection}
      >
        <Box
          bgcolor="white"
          borderRadius="10px"
          mb={2}
          mr={2}
          overflow="hidden"
          flex={1}
        >
          <LimitSettings
            limitFormValues={limitFormValues}
            carrierUuid={carrierUuid}
            editingLimits={editingLimits}
            setEditingLimits={setEditingLimits}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore - TODO: Resolve type mismatch for setLimitFormValues ASAP
            setLimitFormValues={setLimitFormValues}
          />
        </Box>
        <Box borderRadius="10px" mb={2} overflow="hidden" flex={1}>
          <Box mb={2}>
            <PromptSettings
              promptFormValues={promptFormValues}
              carrierUuid={carrierUuid}
              editingPrompts={editingPrompts}
              setEditingPrompts={setEditingPrompts}
              setPromptFormValues={setPromptFormValues}
            />
          </Box>
          <Box mb={2}>
            <TransactionSettings
              editingTransactionSettings={editingTransactionSettings}
              setEditingTransactionSettings={setEditingTransactionSettings}
            />
          </Box>
          {isDtnaCarrier && <TankLevelAuthorization />}
        </Box>
      </Box>
    </>
  );
}
