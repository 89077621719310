import { format } from 'date-fns';

export interface LimitValuesType {
  limitType: string;
  tractorDiesel: string;
  reeferDiesel: string;
  def: string;
  oil: string;
  additives: string;
  merchandise: string;
  cashAdvance: string;
  nonDieselFuels: string;
}

export interface PromptValuesType {
  hasTripNumber: boolean;
  hasTruckNumber: boolean;
  hasTrailerNumber: boolean;
  hasOdometer: boolean;
}

export const LIMIT_GROUP_OPTIONS = {
  FUEL: 'Fuel',
  NON_FUEL: 'Non-fuel',
};

export const PRODUCT_VALUES = {
  PRODUCT_CATEGORY_DIESEL: 'tractorDiesel',
  PRODUCT_CATEGORY_REEFER: 'reeferDiesel',
  PRODUCT_CATEGORY_DEF: 'def',
  PRODUCT_CATEGORY_OIL: 'oil',
  PRODUCT_CATEGORY_ADDITIVES: 'additives',
  PRODUCT_CATEGORY_MERCHANDISE: 'merchandise',
  PRODUCT_CATEGORY_CASH_ADVANCE: 'cashAdvance',
  PRODUCT_CATEGORY_SERVICES: 'services',
  PRODUCT_CATEGORY_MAINTENANCE: 'maintenance',
  PRODUCT_CATEGORY_NON_DIESEL_FUELS: 'nonDieselFuels',
};

export const DRIVER_FUEL_PRODUCT_VALUES = {
  PRODUCT_CATEGORY_DIESEL: 'diesel',
  PRODUCT_CATEGORY_REEFER: 'reefer',
  PRODUCT_CATEGORY_DEF: 'def',
};

export const DRIVER_NON_FUEL_PRODUCT_VALUES = {
  PRODUCT_CATEGORY_OIL: 'oil',
  PRODUCT_CATEGORY_ADDITIVES: 'additives',
  PRODUCT_CATEGORY_MERCHANDISE: 'merchandise',
  PRODUCT_CATEGORY_CASH_ADVANCE: 'cashAdvance',
  PRODUCT_CATEGORY_SERVICES: 'services',
  PRODUCT_CATEGORY_MAINTENANCE: 'maintenance',
  PRODUCT_CATEGORY_NON_DIESEL_FUELS: 'nonDieselFuels',
};

export const PRODUCTS = [
  {
    label: 'Tractor Diesel',
    value: 'tractorDiesel',
    product: 'PRODUCT_CATEGORY_DIESEL',
    isFuel: true,
  },
  {
    label: 'Reefer Diesel',
    value: 'reeferDiesel',
    product: 'PRODUCT_CATEGORY_REEFER',
    isFuel: true,
  },
  {
    label: 'DEF',
    value: 'def',
    product: 'PRODUCT_CATEGORY_DEF',
    isFuel: true,
  },
  {
    label: 'Non Diesel Fuels',
    value: 'nonDieselFuels',
    product: 'PRODUCT_CATEGORY_NON_DIESEL_FUELS',
    isFuel: true,
  },
  {
    label: 'Oil',
    value: 'oil',
    product: 'PRODUCT_CATEGORY_OIL',
    isFuel: false,
  },
  {
    label: 'Additives',
    value: 'additives',
    product: 'PRODUCT_CATEGORY_ADDITIVES',
    isFuel: false,
  },
  {
    label: 'Merchandise',
    value: 'merchandise',
    product: 'PRODUCT_CATEGORY_MERCHANDISE',
    isFuel: false,
  },
  {
    label: 'Cash Advance',
    value: 'cashAdvance',
    product: 'PRODUCT_CATEGORY_CASH_ADVANCE',
    isFuel: false,
  },
  {
    label: 'Services',
    value: 'services',
    product: 'PRODUCT_CATEGORY_SERVICES',
    isFuel: false,
  },
  {
    label: 'Maintenance',
    value: 'maintenance',
    product: 'PRODUCT_CATEGORY_MAINTENANCE',
    isFuel: false,
  },
];

export const LIMIT_OPTIONS = [
  {
    group: LIMIT_GROUP_OPTIONS.FUEL,
    options: [
      {
        label: 'Tractor Diesel',
        value: 'tractorDiesel',
      },
      {
        label: 'Reefer Diesel',
        value: 'reeferDiesel',
      },
      {
        label: 'DEF',
        value: 'def',
      },
      {
        label: 'Non Diesel Fuels',
        value: 'nonDieselFuels',
      },
    ],
  },
  {
    group: LIMIT_GROUP_OPTIONS.NON_FUEL,
    options: [
      {
        label: 'Oil',
        value: 'oil',
      },
      {
        label: 'Additives',
        value: 'additives',
      },
      {
        label: 'Merchandise',
        value: 'merchandise',
      },
      {
        label: 'Cash Advance',
        value: 'cashAdvance',
      },
      {
        label: 'Services (Scales, Tolls and Parking)',
        value: 'services',
      },
      {
        label: 'Maintenance',
        value: 'maintenance',
      },
    ],
  },
];

export const PROMPTS = [
  {
    label: 'Truck',
    name: 'hasTruckNumber',
  },
  {
    label: 'Trip',
    name: 'hasTripNumber',
  },
  {
    label: 'Trailer',
    name: 'hasTrailerNumber',
  },
  {
    label: 'Odometer',
    name: 'hasOdometer',
  },
];

export const limitsInitialValues = {
  limitType: '',
  tractorDiesel: '',
  reeferDiesel: '',
  def: '',
  oil: '',
  additives: '',
  merchandise: '',
  cashAdvance: '',
  services: '',
  maintenance: '',
  nonDieselFuels: '',
};

export const driverLimitInitialValues = {
  limitType: '',
  diesel: '',
  reefer: '',
  def: '',
  oil: '',
  additive: '',
  merchandise: '',
  cashAdvance: '',
  services: '',
  maintenance: '',
  nonDieselFuels: '',
};

export const promptInitialValues = {
  hasTripNumber: false,
  hasTruckNumber: false,
  hasTrailerNumber: false,
  hasOdometer: false,
};

export const transactionSettingsInitialValues = {
  showTimeOnReceipts: null,
};

export const TODAY = format(new Date(), 'yyyyMMdd');
