import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Avatar, ListItemIcon, AppBar as MUIAppBar } from '@mui/material';
import { ChevronRight, Logout } from '@mui/icons-material';
import AuthService from '@/fleet/services/auth';
import useCarrier from '@/fleet/hooks/useCarrier';

interface AppBarProps {
  isDrawerOpen: boolean;
  onToggleDrawer: () => void;
}

export default function AppBar({ isDrawerOpen, onToggleDrawer }: AppBarProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const carrier = useCarrier();

  return (
    <MUIAppBar
      position="fixed"
      sx={{
        background: '#fff',
        boxShadow: 1,
        transition: (theme) =>
          theme.transitions.create(['margin-left', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        marginLeft: isDrawerOpen ? `240px` : '60px',
      }}
    >
      <Toolbar>
        {!isDrawerOpen && (
          <IconButton
            onClick={onToggleDrawer}
            sx={{
              bgcolor: 'gray',
              height: '25px',
              width: '25px',
              marginInlineEnd: '15px',
              '&:hover': { bgcolor: 'black' },
            }}
          >
            <ChevronRight sx={{ color: 'yellow' }} />
          </IconButton>
        )}
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ marginLeft: isDrawerOpen ? '250px' : '0px', color: '#000' }}
        >
          {carrier.name}
        </Typography>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
        {AuthService.isLoggedIn() && (
          <div>
            <IconButton
              size="large"
              sx={{ ml: 2 }}
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <Avatar sx={{ width: 32, height: 32, background: '#000' }}>
                {AuthService.getInitial()}
              </Avatar>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem>
                <ListItemIcon>
                  <Avatar />
                </ListItemIcon>
                {AuthService.getUsername()}
              </MenuItem>
              <MenuItem onClick={() => AuthService.doLogout()}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </MUIAppBar>
  );
}
