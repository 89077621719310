import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { Formik } from 'formik';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import useCarrier from '@/fleet/hooks/useCarrier';
import { updateCarrierService } from '@/fleet/services/carriers';

type TransactionSettings = {
  setEditingTransactionSettings: Dispatch<SetStateAction<boolean>>;
  editingTransactionSettings: boolean;
};

export const TransactionSettings = ({
  setEditingTransactionSettings,
  editingTransactionSettings,
}: TransactionSettings) => {
  const carrier = useCarrier();
  const [showTransactionTime, setShowTransactionTime] = useState(
    carrier.showTimeOnReceipts,
  );
  const [showRetailOnEmailedReceipts, setShowRetailOnEmailedReceipts] =
    useState(carrier.showRetailOnEmailedReceipts);

  const handleCheckboxChange = (
    event: { target: { value: any } },
    field: string,
  ) => {
    const { value } = event.target;

    if (field === 'time') {
      setShowTransactionTime(value === 'true' ? true : false);
    }

    if (field === 'price') {
      setShowRetailOnEmailedReceipts(value === 'true' ? true : false);
    }
  };

  useEffect(() => {
    setShowTransactionTime(carrier.showTimeOnReceipts);
    setShowRetailOnEmailedReceipts(carrier.showRetailOnEmailedReceipts);
  }, [carrier]);

  return (
    <Formik
      initialValues={{
        showTransactionTime: carrier.showTransactionTime,
        showRetailOnEmailedReceipts: carrier.showRetailOnEmailedReceipts,
      }}
      enableReinitialize
      onSubmit={() => {
        const payload = {
          carrier: {
            ...carrier,
            showTimeOnReceipts: showTransactionTime,
            showRetailOnEmailedReceipts: showRetailOnEmailedReceipts,
          },
        };

        updateCarrierService(payload, carrier.uuid)
          .then(() => {
            toast.success('Changes made successfully!');
          })
          .catch((e) => {
            toast.error(
              `Unable to make changes. Please try again later. Error: ${e}`,
            );
          })
          .finally(() => setEditingTransactionSettings(false));
      }}
    >
      {({ handleSubmit, resetForm, dirty, setFieldValue }) => (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={2}
            border={1}
            borderColor="#D4D4D4"
            bgcolor="#F8F8FA"
            fontWeight={600}
            fontSize={14}
            style={{
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
          >
            <Box>TRANSACTIONS</Box>
            <Box>
              {editingTransactionSettings && (
                <Button
                  sx={{
                    width: 100,
                    paddingX: 3,
                    paddingY: 1,
                    mr: 1,
                    bgcolor: 'black',
                    color: 'white',
                    borderRadius: 1,
                    '&:hover': {
                      backgroundColor: 'gray',
                    },
                  }}
                  onClick={() => {
                    dirty ? handleSubmit() : toast('No changes made');
                  }}
                >
                  Save
                </Button>
              )}
              <Button
                sx={{
                  width: 100,
                  paddingX: 3,
                  paddingY: 1,
                  border: 1,
                  borderColor: editingTransactionSettings ? 'gray' : null,
                  bgcolor: editingTransactionSettings ? 'white' : 'black',
                  color: editingTransactionSettings ? 'black' : 'white',
                  borderRadius: 1,
                  '&:hover': {
                    backgroundColor: 'gray',
                  },
                }}
                onClick={() => {
                  if (editingTransactionSettings) {
                    setEditingTransactionSettings(false);
                    resetForm({
                      values: {
                        showTransactionTime: carrier.showTransactionTime,
                        showRetailOnEmailedReceipts:
                          carrier.showRetailOnEmailedReceipts,
                      },
                    });
                  } else {
                    setEditingTransactionSettings(true);
                  }
                }}
              >
                {editingTransactionSettings ? 'Cancel' : 'Edit'}
              </Button>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            // p={2}
            border={1}
            borderTop={0}
            borderColor="#D4D4D4"
            bgcolor="white"
            sx={{
              borderBottomRightRadius: 10,
              borderBottomLeftRadius: 10,
            }}
          >
            <FormControl
              sx={{ p: 5, borderBottom: 1, borderBottomColor: '#D4D4D4' }}
              component="fieldset"
              variant="standard"
            >
              <Box>Show transaction time on receipts:</Box>
              <FormGroup sx={{ flexDirection: 'row' }}>
                <FormControlLabel
                  sx={{ mr: 5, mt: 2 }}
                  disabled={!editingTransactionSettings}
                  control={
                    <Checkbox
                      checked={showTransactionTime === true}
                      onChange={(e) => {
                        handleCheckboxChange(e, 'time');
                        if (!showTransactionTime) {
                          setFieldValue('showTransactionTime', true);
                        }
                      }}
                      name="showTransactionTime"
                      value={true}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  sx={{ mr: 5, mt: 2 }}
                  disabled={!editingTransactionSettings}
                  control={
                    <Checkbox
                      checked={showTransactionTime === false}
                      onChange={(e) => {
                        handleCheckboxChange(e, 'time');
                        if (showTransactionTime) {
                          setFieldValue('showTransactionTime', false);
                        }
                      }}
                      name="showTransactionTime"
                      value={false}
                    />
                  }
                  label="No"
                />
              </FormGroup>
            </FormControl>
            <FormControl sx={{ p: 5 }} component="fieldset" variant="standard">
              <Box>Show retail price on emailed receipts:</Box>
              <FormGroup sx={{ flexDirection: 'row' }}>
                <FormControlLabel
                  sx={{ mr: 5, mt: 2 }}
                  disabled={!editingTransactionSettings}
                  control={
                    <Checkbox
                      checked={showRetailOnEmailedReceipts === true}
                      onChange={(e) => {
                        handleCheckboxChange(e, 'price');
                        if (!showRetailOnEmailedReceipts) {
                          setFieldValue('showRetailOnEmailedReceipts', true);
                        }
                      }}
                      name="showRetailOnEmailedReceipts"
                      value={true}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  sx={{ mr: 5, mt: 2 }}
                  disabled={!editingTransactionSettings}
                  control={
                    <Checkbox
                      checked={showRetailOnEmailedReceipts === false}
                      onChange={(e) => {
                        handleCheckboxChange(e, 'price');
                        if (showRetailOnEmailedReceipts) {
                          setFieldValue('showRetailOnEmailedReceipts', false);
                        }
                      }}
                      name="showRetailOnEmailedReceipts"
                      value={false}
                    />
                  }
                  label="No"
                />
              </FormGroup>
            </FormControl>
          </Box>
        </>
      )}
    </Formik>
  );
};
