import { setHours, setMinutes, setSeconds } from 'date-fns';

export const defaultStartDate = setSeconds(
  setMinutes(setHours(new Date(), 0), 0),
  0,
);
export const defaultEndDate = setSeconds(
  setMinutes(setHours(new Date(), 23), 59),
  59,
);
