export enum PathPrivate {
  Dashboard = '/carriers/:carrierUuid/dashboard',
  Drivers = '/carriers/:carrierUuid/drivers',
  Driver = '/carriers/:carrierUuid/drivers/:driverUuid',
  Members = '/carriers/:carrierUuid/members',
  Settings = '/carriers/:carrierUuid/settings',
  Stations = '/carriers/:carrierUuid/stations',
  Tractors = '/carriers/:carrierUuid/tractors',
  Tractor = '/carriers/:carrierUuid/tractors/:tractorUuid',
  Reports = '/carriers/:carrierUuid/reports',
  OneTimeLimit = '/carriers/:carrierUuid/one-time-limit',
  PaymentHistory = '/carriers/:carrierUuid/payment-history',
}

export enum PathPublic {
  Index = '/',
  Card = '/applications/card',
  Credit = '/applications/credit',
  Login = '/login',
  Logout = '/logout',
  Denied = '/access-denied',
  NotFound = '/not-found',
}
