import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ErrorAlert from '@/fleet/components/common/alerts/error';
import SuccessAlert from '@/fleet/components/common/alerts/success';
import { TransactionDetailsModal } from '@/fleet/components/views/discount-reports/TransactionDetailsModal';
import { format, isValid, parse } from 'date-fns';
import { isEmpty } from 'lodash';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { listDiscountReport } from '@/fleet/services/transactions';

type DiscountTypeEnum = 'Discount' | 'Transaction' | 'Discount Expiration';

const DiscountTypeEnumObject: Record<string, DiscountTypeEnum> = {
  DISCOUNT_GALLON_ROW_TYPE_DISCOUNT: 'Discount',
  DISCOUNT_GALLON_ROW_TYPE_TRANSACTION: 'Transaction',
  DISCOUNT_GALLON_ROW_TYPE_DISCOUNT_EXPIRATION: 'Discount Expiration',
};

const shortDateRegex = /^\d{8}$/;
const longDateRegex = /^\d{4}\/\d{2}\/\d{2} \d{2}:\d{2}:\d{2} [A-Z]{3}$/;

interface StyleButtonProps {
  children: any;
  onClick: any;
  inverted?: boolean;
  style?: any;
  disabled?: boolean;
}

const StyledButton = ({
  children,
  inverted = false,
  onClick,
  style,
  disabled,
}: StyleButtonProps) => (
  <Button
    onClick={onClick}
    sx={{
      height: 40,
      textTransform: 'none',
      background: inverted ? '#fff' : '#181818',
      color: inverted ? '#000' : '#fff',
      '&:hover': {
        backgroundColor: inverted ? 'light-gray' : '#353535',
      },
      ':disabled': {
        background: '#ccc',
      },
      padding: '15px',
      ...style,
    }}
    disabled={disabled}
  >
    {children}
  </Button>
);

const Pill = ({ amount }: { amount: string | number }) => {
  return (
    <Box ml={1} borderRadius={2} bgcolor="green" px="10px" py="2px">
      <Typography color="white" fontSize={14} fontWeight={600}>
        {amount}
      </Typography>
    </Box>
  );
};

const parseDate = (dateString: string): string | null => {
  if (shortDateRegex.test(dateString)) {
    // If the input is in 'YYYYMMDD' format
    const parsedDate = parse(dateString, 'yyyyMMdd', new Date());
    return format(parsedDate, 'MM/dd/yyyy');
  }

  if (longDateRegex.test(dateString)) {
    // If the input is in 'YYYY/MM/DD HH:mm:ss zzz' format
    const parsedDate = new Date(dateString);
    return format(parsedDate, 'MM/dd/yyyy');
  }

  // If the format is not recognized, return null
  return null;
};

export const DiscountReports = () => {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  yesterday.setHours(0, 1, 0, 0); // Set the time to 12:01 AM

  today.setHours(23, 59, 59, 999); // Set the time to 11:59:59 PM

  const { carrierUuid } = useParams();
  const [data, setData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [filterStartDate, setFilterStartDate] = useState<Date | null>(
    yesterday,
  );
  const [filterEndDate, setFilterEndDate] = useState<Date | null>(today);
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [filterApplied, setFilterApplied] = useState<boolean>(true);
  const [message, setMessage] = useState('');
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState<any>(null);
  const [showTransactionDetailsModal, setShowTransactionDetailsModal] =
    useState<boolean>(false);

  const filterStartDateRef = useRef<Date | null>(yesterday);
  const filterEndDateRef = useRef<Date | null>(today);

  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'Date',
      name: 'dateTime',
      options: {
        customBodyRender: (value: string) => {
          const dateString = value;
          // Parse the date based on the format
          const formattedDate = parseDate(dateString);
          if (formattedDate) {
            return <Box>{formattedDate}</Box>;
          } else {
            // If the date format is not recognized, display the original string
            return <Box>{dateString}</Box>;
          }
        },
      },
    },
    {
      label: 'Type',
      name: 'type',
      options: {
        customBodyRender: (value: string) => DiscountTypeEnumObject[value],
      },
    },
    {
      label: '# of Gallons',
      name: 'quantity',
    },
    {
      label: 'Discount gallons remaining',
      name: 'balance',
    },
    {
      label: 'Expiration Date',
      name: 'discount.endDate',
      options: {
        customBodyRender: (value: string) => {
          const dateString = value;
          // Parse the date based on the format
          const formattedDate = parseDate(dateString);
          if (formattedDate) {
            return <Box>{formattedDate}</Box>;
          } else {
            return <Box>NA</Box>;
          }
        },
      },
    },
    {
      label: ' ',
      name: 'transaction',
      options: {
        customBodyRender: (value) => {
          if (value) {
            return (
              <Button
                variant="contained"
                size="small"
                sx={{ bgcolor: 'black', ':hover': { bgcolor: 'gray' } }}
                onClick={() => {
                  setTransactionDetails(value);
                  setShowTransactionDetailsModal(true);
                }}
              >
                View Details
              </Button>
            );
          }
        },
      },
    },
  ];

  const handleApplyFilter = async () => {
    let filteredTransactions: any[] = [];

    if (filterStartDate && filterEndDate) {
      filteredTransactions = await data.rows.filter(
        (e: any) =>
          new Date(e.dateTime) >= filterStartDate &&
          new Date(e.dateTime) <= filterEndDate,
      );
    }

    filterStartDateRef.current = filterStartDate;
    filterEndDateRef.current = filterEndDate;

    await setFilterApplied(true);
    await setFilteredData(filteredTransactions);
  };

  const handleRemoveFilter = () => {
    setFilterStartDate(null);
    setFilterEndDate(null);
    setFilterApplied(false);
  };

  const cuuid = carrierUuid !== undefined ? carrierUuid.toString() : '';

  useEffect(() => {
    if (cuuid) {
      listDiscountReport(cuuid)
        .then((response) => {
          if (response.status === 200) {
            setData(response.data);
          } else {
            setMessage(
              `There was an issue with
                  code:${response.data.code} loading transactions,
                  contact support.`,
            );
          }
        })
        .catch(() => {
          setMessage(
            'There was an issue loading transactions, contact support.',
          );
        });
    }
  }, [cuuid]);

  useEffect(() => {
    if (
      filterEndDate !== filterEndDateRef.current ||
      filterStartDate !== filterStartDateRef.current
    ) {
      setFilterApplied(false);
    }
  }, [filterStartDate, filterEndDate]);

  useEffect(() => {
    if (filterStartDate && filterEndDate) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [filterStartDate, filterEndDate]);

  return (
    <Box height={400} width="100%">
      <Box>
        <Typography fontWeight="600">Discounted gallons summary:</Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        mb={4}
        mt={1}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography fontSize={14}>Used (all time) - </Typography>
          <Pill amount={data?.usedAllTime} />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography fontSize={14} ml={5}>
            Available -
          </Typography>
          <Pill amount={data?.availableDiscount} />
        </Box>
      </Box>
      <Box
        marginBottom={4}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" justifyContent="center">
          <Box display="flex" flexDirection="column">
            <FormControl sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start Date"
                  maxDate={filterEndDate}
                  value={filterStartDate}
                  onChange={(newValue) => {
                    isValid(newValue) && setFilterStartDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      sx={{ width: '50%', mr: 1 }}
                    />
                  )}
                />
                <TimePicker
                  label="Start Time"
                  value={filterStartDate}
                  onChange={(newValue) => {
                    isValid(newValue) && setFilterStartDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField size="small" {...params} sx={{ width: '50%' }} />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="End Date"
                  minDate={filterStartDate}
                  value={filterEndDate}
                  onChange={(newValue) => {
                    isValid(newValue) && setFilterEndDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      sx={{ width: '50%', mr: 1 }}
                    />
                  )}
                />
                <TimePicker
                  label="End Time"
                  value={filterEndDate}
                  onChange={(newValue) => {
                    isValid(newValue) && setFilterEndDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField size="small" {...params} sx={{ width: '50%' }} />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>
          <Box
            sx={{
              ml: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <StyledButton
              disabled={disableButton}
              onClick={filterApplied ? handleRemoveFilter : handleApplyFilter}
            >
              {filterApplied ? 'Remove Filter' : 'Apply Filter'}
            </StyledButton>
          </Box>
        </Box>
      </Box>
      <MUIDataTable
        columns={columns}
        // Show filteredData if filterData is not empty or filter dates are applied
        // so filteredData appears in table even when dates are being modified
        data={
          filterApplied ||
          !isEmpty(filteredData) ||
          (filterStartDate && filterEndDate)
            ? filteredData
            : data?.rows
        }
        title="Earned Discount Transactions"
        options={{
          enableNestedDataAccess: '.',
          selectableRowsHideCheckboxes: true,
          filter: false,
          download: false,
          print: false,
          rowsPerPage: 10,
          rowsPerPageOptions: [10, 20, 50],
          textLabels: {
            body: {
              noMatch: !data ? (
                <CircularProgress
                  sx={{
                    color: '#181818',
                  }}
                />
              ) : (
                'No transactions found'
              ),
            },
          },
        }}
      />
      <SuccessAlert
        message={message}
        open={openSuccess}
        handleClose={() => setOpenSuccess(false)}
      />
      <ErrorAlert
        message={message}
        open={openError}
        handleClose={() => setOpenError(false)}
      />
      {showTransactionDetailsModal ? (
        <TransactionDetailsModal
          setOpen={setShowTransactionDetailsModal}
          details={transactionDetails}
        />
      ) : null}
    </Box>
  );
};
